import React from 'react'
import Layout from '../components/layout/Layout'

const colors = {
  // colordeepnavyblue: '#242943',
  // colornavyblue: '#2a2f4a',
  // colorbabyblue: '#9bf1ff',
  // colorpaleblue: '#6fc3df',
  // colorpurple: '#8d82c4',
  // colorcoral: '#ec8d81',
  // colorflesh: '#e7b788',
  // colorperiwinkle: '#8ea9e8',
  // colormint: '#87c5a4',
  // colorblack: '#000',
  // colorwhite: '#fff',
  // colorneongreen: '#00ff00',
  // colorneonpink: '#ff0dbf',

  // colorlightskyblue: '#61dafb',
  colorrosybrown: '#a89181',
  colorpalesilver: '#d3c4b9',
  colordeeptaup: '#736461',
  // colordavysgrey: '#53525c',
  colorarmygreen: '#676a5e',
  colorcamouflagegreen: '#788359',
  colorpastelgray: '#caccc0',
  colorlightskyblue: '#83c6fb',
  colordiamondblue: '#b2efff',
  colorsteelteal: '#648e81',
  colorouterspace: '#454c52',
  colormoss: '#6e7963',
  colorgrullo: '#a89181',
  colortrolleygrey: '#81847e',
  colorolive: '#565a4b',
  colorblackolive: '#393b3a',
  colorlogogreen: '#60795c',
  colorsmoke: '#4c4647',
  colorlightsmoke: '#726e70',
  colortreelandlogo: '#969696',
  colortrailslogo: '#a58071',
  colorpalecream: '#f8f4ee',
  colorbuttercream: '#f3e9df',
  colorkhaki: '#e4dfd5',
  colordarkkhaki: '#9d8d77',
  colorgold: '#e7c97f',
  colorlightrose: '#e1d0d0',
  colorbrown: '#867560',
  colorcinnamon: '#885745',
  colorsage: '#9a9979',
  colorcashmere: '#c29571',
  colorcoffee: '#a78360',
  colorteacup: '#f4e7d4',
}

const ColorDemo = () => {
  return (
    <Layout>
      <div id="demo" className="page-content">
        {Object.entries(colors).map(([key, value]) => (
          <section
            style={{
              backgroundColor: value,
            }}
          >
            <h2>
              {key},{value}
            </h2>
          </section>
        ))}
      </div>
    </Layout>
  )
}

export default ColorDemo
